* {
    margin: 0;
    padding: 0;
  }

.columns {
    display: flex;
    column-gap: 5px;
}

.gallery .pics {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
}

.model {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.model.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);

}

.model img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.close {
    position:fixed;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 10px;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    color: WHITE;
    cursor: pointer;
}

.arrowBack {
    position:fixed;
    bottom: 140px;
    left: 40px;
    width: 8rem;
    height: 8rem;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    color: WHITE;
    cursor: pointer;
}

.arrowForward {
    position:fixed;
    bottom: 140px;
    right: 40px;
    width: 8rem;
    height: 8rem;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    color: WHITE;
    cursor: pointer;
}