#about {
    background-color: rgb(42, 165, 63);
    text-align: center;
    font-size: 30px;
    font-family: 'Arial';
    padding-top: 120px;
    padding-bottom: 40px;
}

#picture {
    text-align: center;
}

.writing p {
    font-size: 18px;
    font-family: 'Arial';
    margin: 20px;
    text-align: justify;
    text-justify: inter-word;
}

.link {
    text-decoration: none;
}