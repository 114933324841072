* {    
    margin: 0;
    padding: 0;
}

#siteName {
    user-select: none;
    cursor: pointer;
    text-decoration: none;
    color: inherit; 
}

#siteName:hover {
    transition: 1s;
    /* color:rgb(215, 185, 76); */
    color:rgb(78, 149, 182);
}

#header {
    /* background-color: rgb(40, 40, 163); */
    background-color: #000000;
    height: 50px;
    width: 100%;
    color: white;
    font-weight: 500;
    font-family: 'Arial';
    font-size: 30px;
    font-style: italic;
    text-align: center;
    /* border-bottom: 4px solid maroon; */
    position: fixed;
    z-index: 5;
}

#myProjectsButton {
    display: flex;
    margin-right: 15px;
    background-color: rgb(21, 123, 182);
    border-radius: 10px;
    padding: '3px';
    color: white;
    width: 110px;
    height: 15px;
    padding: 10px;
    font-size: 20px;
    text-align: center;
    justify-content: center;
    user-select: none; 
    border: 3px solid rgb(4, 69, 16);
}

#myProjectsButton:hover {   
    transition: 0.5s;
    cursor: pointer;
    color: black;
    /* background-color: rgb(255, 235, 163); */
    background-color: rgb(255, 208, 38);
    padding: 10px;
    /* border: 3px solid orangered; */
    border: 3px solid maroon;
}

#myResumeButton {
    display: flex;
    margin-right: 15px;
    background-color: rgb(61, 165, 38);
    border-radius: 10px;
    padding: '3px';
    color: white;
    width: 110px;
    height: 15px;
    padding: 10px;
    font-size: 20px;
    text-align: center;
    justify-content: center;
    user-select: none; 
    border: 3px solid rgb(4, 69, 16);
}

#myResumeButton:hover {   
    transition: 0.5s;
    cursor: pointer;
    color: black;
    /* background-color: rgb(255, 235, 163); */
    background-color: rgb(255, 208, 38);
    padding: 10px;
    /* border: 3px solid orangered; */
    border: 3px solid maroon;
}

li {

    padding: 5px;
}

#testHeader {
    display: none;
}

#headerButtons {

    /* position: 'absolute', display: 'flex', textAlign: 'center', justifyContent: 'center', justifyItems: 'center', zIndex: 6, right: '5px', top: '5px', height: 30, width: 400}} */
    
    display: block;
    position: absolute;
    display: flex;
    text-align: center;
    justify-items: center;
    z-index: 6;
    right: 5px;
    top: 5px;
    height: 30;
    width: 400;
}


@media (max-width: 990px) {

    #header {
        height: 50px;
        /* background-color: rgb(40, 40, 163); */
        background-color: #000000;
        margin-top: 0px;
        width: 100%;
        color: white;
        font-weight: 500;
        font-family: 'Arial';
        font-size: 30px;
        font-style: italic;
        text-align: center;
        /* border-bottom: 4px solid maroon; */
        z-index: 5;
        
    }

    #headerButtons {
        margin-top: 1.5%;
        justify-content: center;
        position: static;
    }

  
}
